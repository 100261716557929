<template>
  <Portlet
    title="Ticket Sale Count"
    icon="chart-area"
    class="salesCountReport"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <kendo-chart
        ref="chart"
        :legend-visible="false"
        :legend="legend"
        :series-defaults-stack="true"
        :series-defaults-type="'column'"
        :series="series"
        :category-axis="categoryAxis"
        :value-axis="valueAxis"
        :tooltip="tooltip"
        :theme="'sass'"
      >
        />
      </kendo-chart>
    </template>
  </Portlet>
</template>

<script>
import { responsiveKendoGridMixin } from "@/mixins/responsiveKendoGridMixin.js";
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";

export default {
  name: "ReportByType",
  mixins: [dateTimeMixin, responsiveKendoGridMixin],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      series: [],
      legend: {
        position: 'bottom'
      },
      categoryAxis: {
        categories: [],
        majorGridLines: {
          visible: false
        },
        type: "date",
        labels: {
          dateFormats: {
            days: "dd.MM"
          }
        }
      },
      tooltip: {
        visible: true,
        template: "#= series.name #: #= value #"
      },
      valueAxis: [
        {
          line: {
            visible: true
          },
          minorGridLines: {
            visible: true
          }
        }
      ]
    };
  },
  created () {
    this.getSalesTransactionCount();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize () {
      this.responsiveKendoGridMixin_refresh(this.$refs.chart);
    },
    withoutTime (dateTime) {
      var date = new Date(dateTime.getTime());
      date.setHours(0, 0, 0, 0);
      return date;
    },
    getWeekDates () {
      var currentTime = new Date();
      var dates = [];
      var from = null;
      for (var i = 7; i > -2; i--) {
        if (i == 7) {
          var nowFrom = new Date();
          var fromPastDate = currentTime.getDate() - i;
          nowFrom.setDate(fromPastDate);
          from = this.withoutTime(nowFrom);
        } else {
          var now = new Date();
          var pastDate = currentTime.getDate() - i;
          now.setDate(pastDate);
          var obj = {
            from: from,
            to: this.withoutTime(now)
          };
          dates.push(obj);
          from = obj.to;
        }
      }
      this.categoryAxis.categories = dates.map(x => x.from);
      return dates;
    },
    getSalesTransactionCount () {
      this.loading = true;
      var weekDates = this.getWeekDates();
      this.axios
        .get(`/Kibana/GetSalesCount?installationId=${this.installationId}`)
        .then(response => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          
          var hits = response.data.hits.hits;
          if (hits) {
            var hitsWithSession = hits.filter(x => x._source.session);
            var sources = hitsWithSession.map(x => x._source);
            let grouped = sources.reduce(
              (h, obj) =>
                Object.assign(h, {
                  [obj.audit.id]: (h[obj.audit.id] || []).concat(obj)
                }),
              {}
            );
            for (var x in grouped) {
              var seriesObj = {
                name: x
                  .replace("vending.", "")
                  .replace(".orderitem.produce.succeeded", ""),
                data: []
              };

              weekDates.forEach(date => {
                var count = grouped[x].filter(
                  x =>
                    new Date(x.time) >= date.from && new Date(x.time) < date.to
                ).length;
                seriesObj.data.push(count);
              });
              this.series.push(seriesObj);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>